export const texts_en = {
    'Storing data ({0} kB) to dropbox ({1}).': 'Saving data ({0} kB) to Dropbox ({1}).',
    'Storing to dropbox failed. Trying again in {0} seconds': 'Saving to Dropbox failed. Retrying in {0} seconds.',
    'Reading data from dropbox ({0}).': 'Reading data from Dropbox ({0}).',
    'Loading Google Calendar {0}': 'Loading Google Calendar {0}.',
    'Cleared {0} todos deleted more than 4 weeks ago.': 'Cleared {0} tasks deleted more than 4 weeks ago.',
    'Cleared {0} todos done more than 8 weeks ago.': 'Cleared {0} tasks completed more than 8 weeks ago.',
    'Nothing to clean up.': 'Nothing to clean up.',
    'Today': 'Today',
    'This Week': 'This Week',
    'Tomorrow': 'Tomorrow',
    'Yesterday': 'Yesterday',
    'Monday': 'Monday',
    'Tuesday': 'Tuesday',
    'Wednesday': 'Wednesday',
    'Thursday': 'Thursday',
    'Friday': 'Friday',
    'Saturday': 'Saturday',
    'Sunday': 'Sunday',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mar',
    'Apr': 'Apr',
    'May': 'May',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Oct',
    'Nov': 'Nov',
    'Dec': 'Dec',
    'Mon': 'Mon',
    'Tue': 'Tue',
    'Wed': 'Wed',
    'Thu': 'Thu',
    'Fri': 'Fri',
    'Sat': 'Sat',
    'Sun': 'Sun',
    'CW': 'CW',
    'calendar week': 'calendar week',
    'January': 'January',
    'February': 'February',
    'March': 'March',
    'April': 'April',
    'June': 'June',
    'July': 'July',
    'August': 'August',
    'September': 'September',
    'October': 'October',
    'November': 'November',
    'December': 'December',
    'Timer has completed.': 'Timer has completed.',
    'settingsarea-experimental': 'Experimental',
    'setting-calendar-delete-button': 'Delete Calendar Events',
    'setting-weekly-overdue': 'Weekly Overdue',
    'setting-delete-all': 'Delete All Events',
    'setting-debug-prio': 'Debug Mode Priorities',
    'setting-debug-properties': 'Debug Mode Properties',
    'setting-essential-view': 'Essential View',
    'setting-repeated-timers': 'Repeated Alarms',
    'setting-auto-tags': 'Automatic Tags',
    'settingsarea-connections': 'Connections',
    'settingsarea-debug': 'Debug',
    'settingsarea-behavior': 'Behavior',
    'setting-dropbox': 'Dropbox',
    'setting-google-calendar': 'Google Calendar',
    'setting-o365-calendar': 'O365 Calendar',
    'setting-mobile-console': 'Mobile Console',
    'week': 'week',
    'weeks': 'weeks',
    'day': 'day',
    'days': 'days',
    'weekdays': 'weekdays',
    'month': 'month',
    'months': 'months',
    'every': 'every',
    'every-day': 'every day',
    'every-weekday': 'every weekday',
    'every-week': 'every week',
    'every-month': 'every month'
};

export const ui_en = {
    '#today': texts_en.Today,
    '#tomorrow': texts_en.Tomorrow,
    '#monday': texts_en.Monday,
    '#tuesday': texts_en.Tuesday,
    '#wednesday': texts_en.Wednesday,
    '#thursday': texts_en.Thursday,
    '#friday': texts_en.Friday,
    '#saturday': texts_en.Saturday,
    '#sunday': texts_en.Sunday,
    '#overdue': 'Overdue',
    '#this-week': 'This Week',
    '#this-week-strict': 'This Week',
    '#next-week': 'Next Week',
    '#this-month': 'This Month',
    '#this-month-strict': 'This Month',
    '#this-year': 'This Year',
    '#this-year-strict': 'This Year',
    '#this-weekend': 'This Weekend',
    '#eventually': 'Someday',
    '#preferences': 'Preferences',
    '#repeattimers': 'Repeated Alarms',
    '#autotags': 'Automatic Tags',
    '#week-left:title': 'Previous Week',
    '#week-right:title': 'Next Week',
    '#week-today:title': 'Today',
    '#to-search': 'Search',
    '#delete-all': 'Delete All',
    '#add-todo-button': 'New Task',
    '#sync-save-button': 'Save',
    '#purge-todos-button': 'Clean Up Tasks',
    '#dropbox-login-button': 'Connect',
    '#dropbox-logout-button': 'Disconnect',
    '#google-login-button': 'Connect',
    '#google-logout-button': 'Disconnect',
    '#dropbox-file': 'Select File <small class="filename"></small>',
    '#google-calendar-list': 'Select Calendar <small class="calendars"></small>',
    '#about-tio': 'About TIO',
    '#dropboxModalLabel': 'Connect Dropbox',
    '#dropboxModal .modal-body p:nth-child(1)': 'Connect your Dropbox to store all TIO data in the folder <code>/apps/tio.one</code>.',
    '#dropboxModal .modal-body p:nth-child(2)': 'This provides a backup of your data and enables synchronization across devices.',
    '#dropboxModal .modal-body p:nth-child(3)': 'Click "Connect" to be redirected to Dropbox for permission.',
    '#delete-series-modal-label': 'Delete Series',
    '#delete-series-modal .modal-body .before': 'Task',
    '#delete-series-modal .modal-body .after': 'with repetitions to delete:',
    '#delete-series-modal .modal-body option[value="future"]': 'Delete this and future repetitions only',
    '#delete-series-modal .modal-body option[value="all"]': 'Delete all repetitions',
    '#delete-series-modal .modal-footer .modal-submit': 'Delete Series',
    '#dropbox-file-modal-label': 'Select Dropbox File',
    '#dropbox-file-modal .modal-body p span:nth-of-type(1)': 'Select the filename for your TIO data.',
    '#dropbox-file-modal .modal-body p span:nth-of-type(2)': 'You can manage multiple todo lists this way.',
    '#dropbox-file-modal .modal-body p span:nth-of-type(3)': '(TIO files are stored under <code>/apps/tio.one</code>.)',
    '#dropbox-file-modal .modal-body label:nth-of-type(1)': 'Choose an existing name: <small>(and load the file)</small>',
    '#dropbox-file-modal .modal-body label:nth-of-type(2)': 'or enter a new one: <small>(and save your tasks there)</small>',
    '#dropbox-file-modal .modal-footer .modal-submit': 'Select',
    '.modal-cancel': 'Cancel',
    '#connect-dropbox': 'Connect',
    '.i18n-day': 'on the day',
    '.i18n-week': 'in the week',
    '.i18n-month': 'in the month',
    '.i18n-year': 'in the year',
    '.i18n-days': 'days',
    '.i18n-weekdays': 'weekdays',
    '.i18n-weeks': 'weeks',
    '.i18n-months': 'months',
    '.i18n-years': 'years',
    '.i18n-one': 'one',
    '.i18n-two': 'two',
    '.i18n-three': 'three',
    '.i18n-four': 'four',
    '.i18n-five': 'five',
    '.i18n-six': 'six',
    '.i18n-duration': 'Duration',
    '.i18n-notes': 'Notes',
    '.i18n-due': 'Due',
    '.i18n-repeat-every': 'Repeat every',
    '.i18n-sync': 'Sync',
    '.i18n-sync-data': 'Syncing data...',
    '.i18n-loading': 'Loading...',
    '#invalid-feedback-duration': 'Enter duration as number and unit, e.g. <code>10m</code>',
    '#invalid-feedback-title': 'Please enter a title',
    '#invalid-feedback-time': 'Enter date as <code>yyyy-MM-dd</code> (year, month, day) and time as <code>HH:mm</code> (hours, minutes)',
    '#invalid-feedback-prio': 'Priority as max. three-digit, positive or negative number',
    '#titleHelp': 'Title, can contain Markdown: <code>**</code>, <code>__</code>, <code>[]()</code>',
    '.i18n-close': 'Close',
    '.i18n-delete': 'Delete',
    '.i18n-delete-series': 'Delete Series',
    '.i18n-edit-series': 'Edit Series',
    '.i18n-submit': 'Submit',
    '.i18n-title': 'Title',
    '#addTodoModalLabel': 'New Task',
    '#editTodoModalLabel': 'Edit Task',
    '.todo-popover .edit': 'Edit',
    '.todo-popover .delete': 'Delete',
    '.todo-popover .done': 'Mark as done',
    '.todo-popover .undone': 'Mark as undone',
    '#todo-date-label': 'Due Date',
    '#todo-priority-label': 'Priority',
    '#todo-timings-label': 'Timings',
    '#todo-repeat-every-label': 'Repeat',
    '#todo-notes-label': 'Notes',
    '#auto-repeat-every': 'Repeats periodically',
    '#auto-repeat-duration': 'Repeat every {0}',
    '#remove-todo-series': 'Remove series',
    '.i18n-due-in': 'Due in',
    '.i18n-due-in-days': '{0} days',
    '.i18n-due-on': 'Due on',
    '#addRepeatButton': 'Add Repeat',
    '#addRepeatButtonLabel': 'Click to repeat this task at regular intervals. You can specify the interval and frequency, e.g., "every week" or "every 2 days." The task will then repeat automatically.'
};

export const pageinit_en = `<div class="jumbotron">
  <p class="lead"><b>TIO ist ein Aufgabenplaner auf Wochenbasis, offline first</b></p>
  <ul class="list-unstyled">
  <li>Alle Aufgaben einer Woche auf einen Blick.</li>
  <li>Behalte auch überfällige Aufgaben im Auge.</li>
  <li>Alle Daten bleiben in deinem Browser.</li>
  <li>Formatiere deinen Text mit Markdown.</li>
  <li><em>Optional</em>: Synchronisiere Aufgaben in deine Cloud.</li>
  <li><em>Optional</em>: Integriere (Google oder Microsoft Outlook) Kalender-Termine.</li>
  </ul></div>
  <div class="jumbotron" id="cookie-banner">
Diese Website speichert Applikationsdaten im lokalen Speicher Ihres Browsers. 
Durch die Nutzung von Drittanbieterdiensten wie Google Calendar, O365 oder Dropbox können technisch notwendige Cookies gesetzt werden. 
Weitere Informationen findest du in unserer [Datenschutzerklärung].<br><br>
  Wenn du das nicht möchtest, benutze bitte diese Webseite nicht. 
  </div><hr>
  <div class="jumbotron tourinfo">Wenn du möchtest, erklären wir dir TIOs Funktionen, wenn sie erstmals im Bild sind.</div>
`;

export function mainTour_en() {
    return [
      ['body.desktop #cw', 'TIO shows your todos on a weekly base. Here you see the currently shown calendar week and its exact date.<br><br>' +
        'Navigate this tour by clicking Forward/Backward or the right/left cursor keys.<br><br>' +
        'To close this tour just click somewhere outside of this hint.', '0.6.2'],
      ['body:not(.desktop) #cw', 'TIO shows your todos on a weekly base. Here you see the currently shown calendar week and its exact date.<br><br>' +
        'Change the current week by swiping the screen content with your finger to the right or to the left.<br><br>' +
        'Navigate this tour by tapping Forward/Backward.<br><br>' +
        'To close this tour just tap somewhere outside of this hint.', '0.6.2'],
      ['body:not(.mobile) #week-left', 'Click here to show the previous week.'],
      ['body:not(.mobile) #week-today', 'Click here to show the current week.'],
      ['body.desktop.essential-view #week-today', 'Now you see the Essential View of the current week. Click here to switch to the Full View.<br><br>Click again to see the Essential View again.'],
      ['body:not(.mobile) #week-right', 'Click here to show the next week.'],
      ['body:not(.mobile) #timerMenuButton', 'Here you can start a simple timer. After completion a sound will be played.'],
      ['#hamburger', 'This is a menu providing extended functionality.<br><br>' +
        'The button also shows the current status of synchronisation, if you for example connect TIO with your Dropbox:' +
        '<ul><li>blue: Everything is in sync.<li>orange: The last version still needs to be persisted.<li>red: There has been an error.</ul>'],
      ['body.desktop .card:not(.overdue)', '<p>All todos in TIO are sorted in buckets. A bucket represents a day, a week or a month.</p>' +
        '<p>Click into the empty area of a bucket to create a new todo.</p>' +
        '<p>Move todos with drag&drop inside of a bucket or between buckets.</p>'],
      ['body:not(.desktop) .bucket:not(.d-none) .card:not(.overdue)', '<p>All todos in TIO are sorted in buckets. A bucket represents a day, a week or a mon</p>' +
        '<p>Tap into the empty area of a bucket to create a new todo.</p>' +
        '<p>Drag&Drop for todos currently does not yet work on mobiles or tablets.</p>'],
      ['.bucket:not(.d-none) .card:not(.overdue) .card-title .title', 'In the top of the bucket its name is shown.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .title-date', '...and beneath its date or date range.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done)', '<p>Alle Aufgaben einer Karte werden mit ihrem Titel innerhalb der Karte aufgelistet.</p>' +
        '<p>Klicke auf den Titel der Aufgabe, um weitere Details zu sehen.</p>' +
        '<p>Ein Doppelklick auf den Titel öffnet den Bearbeitungsdialog.</p>'],
      ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) input[type="checkbox"]', 'Im linken Bereich ist eine Checkbox.<br>Klicke darauf um eine Aufgabe abzuhaken.<br>Sie wird dann ausgeblendet.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .card-title .duration.badge', 'Die Gesamtdauer der Aufgaben einer Karte (wenn angegeben), steht hier.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .bucket-info .bucket-done:not(:empty)', '<p>Hier steht, wie viele Aufgaben die Karte enthält und wie viele davon schon erledigt sind.</p>' +
        '<p>Klicke darauf, um die erledigten Aufgabe ein- oder auszublenden.</p>'],
      ['.bucket:not(.d-none) #overdue:not(.d-none)', 'Alle Aufgaben, die vor der aktuellen Woche schon erledigt sein sollten, werden in der <span style="color:red; font-weight: bold;">Überfällig</span> Karte aufgelistet.'],
      ['.bucket:not(.d-none) #eventually:not(.d-none)', 'Alle Aufgaben ohne Datum werden in der <span style="color:red; font-weight: bold;">Irgendwann</span> Karte aufgelistet.'],
      ['.bucket:not(.d-none) .card-body.nothing-to-do', 'Karten ohne Aufgaben haben eine Sonne als Wasserzeichen.'],
      ['.bucket:not(.d-none) .card-body.all-done', 'Karten in denen alle Aufgaben erledigt sind, haben einen Erledigt-Haken als Wasserzeichen.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .due-time', 'Soll eine Aufgabe zu einer bestimmten Uhrzeit erledigt werden, wird sie hier angezeigt.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .duration', 'Die Dauer einer Aufgabe wird hier angezeigt.'],
      ['.bucket:not(.d-none) .card:not(.overdue) .todo-container:not(.todo-done) .bi-sticky', 'Aufgaben mit diesem Zeichen enthalten Notizen.'],
    ];
  }
  
  export function addModalTour_en() {
    return [
      ['#addTodoModal .modal-content', 'Der Eingabedialog für neue Aufgaben. Ein Titel muss eingegeben werden, der Rest ist optional.<br><br>' +
        'Du solltest aber auf jeden Fall auch ein Fälligkeitsdatum setzen.<br><br>' +
        'Und eine Dauer ist auch immer sinnvoll. So merkst du früh genug, wenn dein Tag zu voll wird.'],
      ['#addTodoTitle', 'Der Titel der Aufgabe, dieser wird dann in der Wochenübersicht angezeigt.<br><br>' +
        'Du kannst Markdown verwenden (wenn du das nicht kennst, schau z.B. bei <a href="https://de.wikipedia.org/wiki/Markdown#Auszeichnungsbeispiele" target="_blank">Wikipedia</a> nach) ' +
        'um Text <code>*<em>kursiv</em>*</code>, <code>**<b>fett</b>**</code> oder <code>***<b><em>fett kursiv</em></b>***</code> zu schreiben.<br><br>' +
        'Du kannst auch einen <code>[Link](https://tio.one)</code> setzen.'],
      ['#addTodoDuration', 'Optionale Dauer der Aufgabe, z.B. <code>10m</code> (zehn Minuten) oder <code>1h</code> (eine Stunde).'],
      ['#addTodoModal .date-time-input', 'Wann ist die Aufgabe fällig? Kann ein Tag, Woche, Monat oder Jahr sein.<br><br>Das gewählte Datum ist der Tag oder ein Tag in der Woche, usw.'],
      ['#addTodoModal .repeat-input', 'Optional eine Wiederholung der Aufgabe festlegen. Dazu links einen Haken setzen und ein Intervall auswählen, z.B. jeden Werktag ("alle ein Werktage") oder alle zwei Wochen.'],
      ['#addTodoRepeatPrio', 'Eine optionale Priorität der Aufgabe(nserie). Wird für die erste Einsortierung verwendet.<br><br>' +
        'Als Wert kann eine beliebige ganze Zahl verwendet werden. Aufgaben werden aufsteigend nach Priorität sortiert aufgelistet.'],
      ['#addTodoNotes', 'Beliebige Notizen zur Aufgabe. Du kannst hier auch Markdown verwenden. Z.B. auch Auflistungen mit <code>*</code>.'],
      ['#addTodoModal #resetTodo', 'Alle Eingaben in diesem Dialog auf den ursprünglichen Stand zurücksetzen.'],
      ['#addTodoModal .modal-footer .i18n-close', 'Eingabedialog schließen. Alle Eingaben werden verworfen.'],
      ['#addTodoSubmit', 'Neue Aufgabe abspeichern. '],
    ];
  }
  
  export function editModalTour_en() {
    return [
      ['#editTodoModal .modal-content', 'Der Änderungsdialog. Funktioniert genauso wie der Dialog für neue Aufgaben.<br><br>' +
        'Nur die Aktionsknöpfe unterscheiden sich.<br><br>' +
        'Achtung: Gehört die Aufgabe zu einer Serie, bearbeitest du erstmal nur die einzelne Aufgabe. Um die ganze Serie zu bearbeiten, gehe auf "Serie bearbeiten".'],
      ['#deleteTodo.i18n-delete', 'Löscht diese Aufgabe.'],
      ['#deleteTodoSeries.visible', 'Löscht eine Aufgabenserie. Du kannst wählen ob alle Wiederholungen oder nur die gewählte und alle folgenden Wiederholungen.'],
      ['#editTodoSeries.visible', 'Öffnet einen neuen Dialog, um die ganze Serie zu bearbeiten.'],
      ['#editTodoModal .modal-footer .i18n-close', 'Änderungsdialog schließen, alle Änderungen werden verworfen.'],
      ['#editTodoSubmit', 'Änderungen abspeichern.'],
    ];
  }
  
  export function menuTour_en() {
    return [
      ['#hamburger-menu', 'Dies ist das Menü von TIO. Hier findest du einige Funktionen, die nicht so häufig benötigt werden.'],
      ['#tio-version', 'Die aktuelle TIO Version.<br><br>Momentan ist TIO in der Beta-Phase und einige Dinge funktionieren noch nicht richtig.'],
      ['#add-todo-button', 'Neue Aufgabe am heutigen Tag zufügen. Hat die gleiche Funktion wie ein Klick in die heutige Karte.<br><br>Alternativ drücke die <b>+</b>-Taste.'],
      ['#sync-save-button', 'Aufgaben jetzt speichern.<br><br>Normalerweise brauchst du das nicht, da TIO automatisch sofort alles lokal speichert.<br><br>Wenn du TIO mit deiner Dropbox verknüpfst, wird auch regelmäßig der aktuelle Stand dort gespeichert. Wenn das mal nicht funktioniert, kannst du es hier erneut versuchen.'],
      ['#delete-all', 'Alle (wirklich alle!) Aufgabe löschen.<br><br>Mach das nur, wenn du genau weißt, was du tust.'],
      ['#preferences', 'Hier können verschiedene Einstellungen gemacht oder experimentelle Funktionen an- und ausgeschaltet werden.'],
      ['body:not(.dropbox-connected) #dropbox-login-button', 'TIO mit deiner Dropbox verknüpfen - als Backup und zur Synchronisierung zwischen mehreren Geräten.'],
      ['body.dropbox-connected #dropbox-logout-button', 'Dropbox-Verbindung wieder trennen.'],
      ['body.dropbox-connected #dropbox-file', 'Dropbox-Datei auswählen, in der alle TIO-Daten gespeichert werden.'],
      ['body:not(.google-connected) #google-login-button', 'TIO mit deinem Google-Konto verknüpfen und deine Kalender-Termine in TIO übernehmen.<br><br>' +
        'Das ist eine Einweg-Synchronisierung vom Kalender zu TIO. Änderungen im Kalender werden zu TIO übernommen. Wenn du in TIO einen Termin änderst, bleibt das in TIO.<br><br>' +
        'Nach einem Klick auf "Verbinden" öffnet sich sofort der Google-Login-Dialog.'
      ],
      ['body.google-connected #google-logout-button', 'Google-Verbindung wieder trennen.'],
      ['body.google-connected #google-calendar-list', 'Kalender zur Integration auswählen.'],
      ['#about-tio', 'Der Info-Dialog vom Start. Hier kann auch die Tour wieder aktiviert werden.'],
    ];
  }
  
  export function miniSyncTour_en() {
    return [
      ['#small-status', 'Wenn der Status-Knopf aus dem Bild scrollt, erscheint stattdessen diese kleine Version davon.'],
    ];
  }
  
  export function todoDetailsTour_en() {
    return [
      ['.todo-popover .popover-header', 'Dies sind die Details einer Aufgabe, hier oben steht der Titel.'],
      ['.todo-popover .popover-body', 'Hier stehen alle Notizen und weitere Informationen einer Aufgabe.'],
      ['.todo-popover .popover-header .bi-arrow-repeat', 'Eine Serienaufgabe wird durch einen runden Pfeil hier oben markiert.'],
      ['.todo-popover .popover-header', 'Dies sind die Details einer Aufgabe, hier oben steht der Titel.'],
      ['.todo-popover .popover-body .created-title', 'Wann wurde diese Aufgabe erzeugt?'],
      ['.todo-popover .popover-body .repeat-title', 'Nach welchem Schema wird eine Aufgabe wiederholt?'],
      ['.todo-popover .popover-body .original-created-title', 'Wann wurde die Serie erzeugt?'],
      ['.todo-popover .popover-footer .dropdown-toggle', 'Es gibt mehrere Aktionsknöpfe für eine Aufgabe. Dieser Pfeil daneben klappt noch weitere ähnliche Aktionen auf.'],
      ['.todo-popover .popover-footer .to-top', 'Die Aufgabe innerhalb ihrer Karte verschieben.'],
      ['.todo-popover .popover-footer .btn-group>.plus-1w', 'Die Aufgabe in die nächste Woche verschieben.'],
      ['.todo-popover .popover-footer .btn-group>.plus-1d', 'Die Aufgabe auf den nächsten Tag verschieben.'],
      ['.todo-popover .popover-footer .to-0', 'Die Aufgabe für heute einplanen.'],
      ['.todo-popover .popover-footer .clone', 'Eine 1:1 Kopie dieser Aufgabe erzeugen.'],
      ['.todo-popover .popover-footer .edit', 'Diese Aufgabe bearbeiten. Bei Serienaufgaben gibt es dort auch die Möglichkeit, die Serie zu bearbeiten.'],
      ['.todo-popover .popover-footer .delete', 'Diese (Einzel-)Aufgabe löschen.'],
    ];
  }
  