import { openAddTodoModal, openLastEditModal } from './add-edit-modals';
import { forceSync } from './dropbox-sync';
import {previousWeek, nextWeek} from './nav';
import { closeAnyPopover } from './events';
import { openSearchModal } from './search';
import { isEnabled } from './features';

export function initKeyboard() {
  document.onkeydown = function (evt) {
    const modals = document.querySelectorAll('.modal.show').length;
    const drivers = document.querySelectorAll('.driver-active').length;
    if (modals > 0 || drivers > 0) {
      return;
    }
    if (evt.ctrlKey && evt.keyCode === 83) { // CTRL-s
      forceSync();
      return false;
    } else if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 76) { // CTRL-SHIFT-l
      openLastEditModal();
    } else if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 70) { // CTRL-SHIFT-f
      openSearchModal();
      return false;
    }
    if (!document.querySelector('body').classList.contains('modal-open')) {
      switch (evt.key) {
        case 'ArrowLeft':
          previousWeek();
          break;
        case 'ArrowRight':
          nextWeek();
          break;
        case '+':
          if (!evt.ctrlKey) {
            closeAnyPopover(evt);
            openAddTodoModal();
          }
          break;
        case 'Escape':
          closeAnyPopover(evt);
      }
    }
  };
}