import {startOfWeek, fmtDate, startOfYear, startOfMonth, fmtTime, endOfDay, endOfWeek, endOfMonth, endOfYear, isWithinTwoWeeks, infoDateDay} from './dates';
import {loadTodos, saveTodos} from './storage';
import { toast } from './alerts';
import { modal } from './modal';
import { saveSettings, settings } from './settings';
import { isEnabled } from './features';
import { applyAutotags, removeAutoPrefix } from './tags';

const todoPrefix = 'todo-';
const millisPerWeek = 60000 * 60 * 24 * 7;
export const todoMap = loadTodos();

export function formatDuration(minutes) {
  if (minutes <= 0) {
    return '';
  }
  let text;
  if (minutes < 60) {
    text = minutes + 'm';
  } else if (minutes < 60 * 24) {
    text = Math.round(minutes / 60) + 'h';
  } else {
    text = Math.round(minutes / (60 * 24)) + 'd';
  }
  let markerClass = 'secondary';
  if (minutes > 8 * 60) {
    markerClass = 'danger';
  } else if (minutes > 7 * 60) {
    markerClass = 'warning';
  }

  return `<span class="duration badge badge-${markerClass}" title="${formatDurationTitle(minutes)}">${text}</span>`;
}

export function formatDurationTitle(minutes) {
  const hours = Math.floor(minutes / 60);
  const minutesRem = minutes % 60;
  let text = '';
  if (hours > 0) {
    text = hours + ' hours';
    if (minutesRem > 0) {
      text += ', ';
    }
  }
  if (minutesRem > 0) {
    text += minutesRem + ' minutes';
  }
  return text;
}

export function updateTodos() {
  const newTodos = loadTodos();
  todoMap.clear();
  for (let todo of newTodos.values()) {
    todoMap.set(todo.id, todo);
  }
}

export function getHighestPriority(date, type, done, withoutId) {
  let highest = Number.MIN_SAFE_INTEGER;
  let found = false;
  const dayMatch = fmtDate(date);
  const weekMatch = startOfWeek(date);
  const monthMatch = startOfMonth(date);
  for (let todo of todoMap.values()) {
    if (todo.id !== withoutId) {
      if (todo.done && done || !todo.done && !done) {
        if (todo.due && todo.due.type === type) {
          if (type === 'week' && startOfWeek(todo.due.date).getTime() === weekMatch.getTime() || 
          type === 'month' && startOfMonth(todo.due.date).getTime() === monthMatch.getTime() ||
          fmtDate(todo.due.date) === dayMatch) {
            const prio = todo.priority*1;
            if (prio > highest) {
              highest = prio;
              found = true;
            }
          }
        }
      }
    }
  }
  if (!found) {
    highest = 0;
  }
  console.log(`Highest prio for ${dayMatch}/${type}/${!!done}: ${highest}`);
  return highest;
}

export function listTodos(filter) {
  const result = [];
  for (let todo of todoMap.values()) {
    if (matchesFilter(todo, filter)) {
      result.push(todo);
    }
  }
  return result;
}

export function allTagsInUse() {
  const nonDeleted = listTodos({deleted: true});
  const tags = [];
  nonDeleted.forEach(todo => {
    if (todo.tags) {
      todo.tags.forEach(tag => {
        tag = removeAutoPrefix(tag);
        if (!tags.includes(tag)) {
          tags.push(tag);
        }
      });
    }
  });
  tags.sort();
  return tags;
}

export function singleTodos() {
  return listTodos({type: ['single', 'instance'], deleted: true, imported: true, done: true});
}

export function repeatedTodos() {
  return listTodos({type: ['repeated']});
}

function matchesFilter(todo, filter) {
  if (!filter) {
    return true;
  }
  if (filter.contains && todo.title.toUpperCase().indexOf(filter.contains.toUpperCase()) < 0) {
    return false;
  }
  let thisType;
  if (todo.repeat) {
    thisType = 'repeated';
  } else if (todo.instanceOf) {
    thisType = 'instance';
  } else {
    thisType = 'single';
  }
  if (filter.type && !filter.type.includes(thisType)) {
    return false;
  }
  if (!filter.imported && todo.imported) {
    return false;
  }
  if (!filter.deleted && todo.deleted) {
    return false;
  }
  if (!filter.done && todo.done) {
    return false;
  }
  return true;
}

/**
 * Purge todos that 
 * <ul>
 * <li>have been deleted four weeks ago and have no due date or were due more that one week ago</li>
 * <li>have been done eight weeks ago</li>
 * </ul>
 */
export function purgeTodos() {
  const PURGE_DELETED = 4 * millisPerWeek;
  const PURGE_DONE = 8 * millisPerWeek;
  const deleteIds = [];
  const doneIds = [];
  const purgeDeletedBefore = new Date(new Date().getTime() - PURGE_DELETED);
  const gone = new Date(new Date().getTime() - 1 * millisPerWeek);
  const purgeDoneBefore = new Date(new Date().getTime() - PURGE_DONE);

  for (let todo of todoMap.values()) {
    if (todo.repeat) {
      if (todo.deleted && new Date(todo.deleted) < purgeDeletedBefore) {
        deleteIds.push(todo.id);
      }
    } else {
      let due;
      if (todo.due && todo.due.date) {
        due = new Date(todo.due.date);
      }
      if (todo.deleted && new Date(todo.deleted) < purgeDeletedBefore && (!due || due < gone)) {
        deleteIds.push(todo.id);
      } else if (todo.done && new Date(todo.done) < purgeDoneBefore) {
        doneIds.push(todo.id);
      }
    }
  }
  
  const purgeDeleted = deleteIds.length;
  const purgeDone = doneIds.length;
  if (purgeDeleted > 0 || purgeDone > 0) {
    if (settings.purge === 'disallow') {
      toast(`Not clearing {0} outdated todos.`, undefined, purgeDeleted + purgeDone);
      return todoMap;
    } else if (settings.purge !== 'allow') {
      modal('purge', 'Wipe out old data?', 
      `<p>There are many todos that have been done or deleted some time ago. 
        Probably you do not need them any more and they waste storage.</p>
        <p>Can we wipe them (done 4 weeks or deleted 8 weeks ago) out on a regular base?</p>`, 'Wipe out', 
        () => {
          settings.purge = 'allow'; 
          saveSettings();
          window.location.reload();
        }, 'Cancel',
        () => {settings.purge = 'disallow'; saveSettings();});
    }
    if (settings.purge === 'allow') {
      if (purgeDeleted > 0) {
        deleteIds.forEach(todoId => todoMap.delete(todoId));
        toast(`Cleared {0} todos deleted more than 4 weeks ago.`, undefined, purgeDeleted);
      }
      if (purgeDone > 0) {
        doneIds.forEach(todoId => todoMap.delete(todoId));
        toast(`Cleared {0} todos done more than 8 weeks ago.`, undefined, purgeDone);
      }
    }
  }
  return purgeDeleted + purgeDone;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function uniqueId() {
  let id = todoPrefix + getRandomInt(1000000000);
  while (todoMap.has(id)) {
    id = todoPrefix + getRandomInt(1000000000);
  }
  return id;
}

export function copyItem(item) {
  const newItem = JSON.parse(JSON.stringify(item));
  newItem.id = uniqueId();
  todoMap.set(newItem.id, newItem);
  return newItem; 
}

export function cloneRepeated(item, dueDate = null, dueType = null) {
  const copy = copyItem(item);
  if (!copy.instanceOf) {
    copy.instanceOf = item.id;
  }

  delete copy.repeat;
  copy.created = new Date();
  if (dueDate) {
    copy.due.date = dueDate;
  }
  if (dueType) {
    copy.due.type = dueType;
  }
  if (item.repeat && item.repeat.prio) {
    copy.repeatpriority = item.repeat.prio;
  }
  if (!isWithinTwoWeeks(copy.due.date)) {
    copy.new = true;
  }
  return copy;
}

export function setPriority(item, priority, type = 'unknown') {
  let dueString = '-';
  if (item.due) {
    dueString = infoDateDay(item.due.date);
  }
  console.log(`Change prio for ${item.id} ('${item.title}', ${dueString}): ${item.priority}->${priority}`);
  if (isEnabled('debug-prio')) {
    if (!item.prevprio) {
      item.prevprio = [{
        type: 'new',
        time: new Date(),
        prio: item.priority
      }];
    }
    item.prevprio.push({
      type: type,
      time: new Date(),
      prio: priority
    });
  }
  item.priority = priority;
}

export function updateItem(item, title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes) {
  if (item.instanceOf && item.due && (item.due.type !== dueType || item.due.date !== dueDate)) {
    // repeat instance needs to stay on its original day
    console.log('Duplicate instanceOf for date change.');
    const copyToDelete = cloneRepeated(item);
    item.wasInstanceOf = item.instanceOf;
    delete item.instanceOf;
    copyToDelete.deleted = new Date();
    delete copyToDelete.new;
  }
  delete item.new;
  const newItem = createItemNoStore(title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes);
  item.title = newItem.title;
  let oldTime = '';
  let newTime = '';
  if (newItem.due && !repeat) {
    oldTime = item.due ? item.due.time : '';
    newTime = newItem.due.time;
  }
  item.due = newItem.due;
  item.repeat = newItem.repeat;
  item.duration = newItem.duration;
  item.notes = newItem.notes;
  item.priority = newItem.priority;
  // time changed for single element => sort by time, not priority
  if (oldTime !== newTime) {
    delete item.priority;
  }
}

export function createItem(title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes) {
  const newItem = createItemNoStore(title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes);
  todoMap.set(newItem.id, newItem);
  return newItem;
}

function createItemNoStore(title, due, dueType, dueDate, dueTime, repeat, repeatEvery, repeatScope, repeatPrio, duration, notes) {
  const timeRegex = /^[0-2]?[0-9]:[0-5][0-9](\s|$)/;
  const matchTime = title.match(timeRegex);
  if (!dueTime && matchTime) {
    const parsedTime = matchTime[0];
    dueTime = fmtTime(parsedTime.trim());
    title = title.substring(parsedTime.length).trim();
  }
  const newItem = {
    created: new Date(),
    title: title,
    notes: notes,
    duration: duration,
    done: false,
    priority: repeatPrio.length > 0 ? repeatPrio*1 : undefined
  };
  newItem.id = uniqueId();
  if (due) {
    switch (dueType) {
      case 'week':
        dueDate = fmtDate(startOfWeek(new Date(dueDate)));
        break;
      case 'month':
        dueDate = fmtDate(startOfMonth(new Date(dueDate)));
        break;
      case 'year':
        dueDate = fmtDate(startOfYear(new Date(dueDate)));
        break;
    }
    newItem.due = {
      type: dueType,
      date: dueDate
    };
    if (dueTime) {
      if (typeof(dueTime) === 'string') {
        newItem.due.time = dueTime;
      } else {
        newItem.due.time = fmtTime(dueTime);
      }
    }
  }
  if (repeat) {
    newItem.repeat = {
      every: parseInt(repeatEvery),
      scope: repeatScope,
      prio: repeatPrio
    };
  }
  applyAutotags(newItem);
  return newItem;
}

export function itemEndDate(item) {
  if (!item.due || item.repeat) {
    return undefined;
  }
  const startDate = new Date(item.due.date);
  switch (item.due.type) {
    case 'day':
      return endOfDay(startDate);
    case 'week':
      return endOfWeek(startDate);
    case 'month':
      return endOfMonth(startDate);
    case 'year':
      return endOfYear(startDate);
  }
  return undefined;
}
